* {
  box-sizing: border-box;
  --main-color: rgba(72, 183, 141, 0.65);
  --text-color: #1d1d35;
  font-weight: 600;
  font-size: 95%;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: "Open Sans", "Arial", monospace;
}

small {
  font-size: 0.75rem;
}

i {
  font-size: 1.5rem;
  color: black;
}

a,
a:visited {
  text-decoration: none;
  color: white;
}

a.active,
a:visited.active {
  text-decoration: underline;
  color: yellowgreen;
  transition: all 1s ease;
}
