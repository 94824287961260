.top-logo {
  max-width: 100%;
  margin-bottom: 20px;
}

.top-logo img {
  padding-top: 20px;
  width: 100%;
  height: 140px;
  object-fit: contain;
  border-top: 2px solid var(--main-color);
}
