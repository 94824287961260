/* The Whole Navigation Bar */
.navbar {
  z-index: 99;
  position: fixed;
  font-family: "Rancho", cursive;
}
/* The Hamburger icon */
.nav-icon {
  padding: 10px;
  padding-right: 5px;
  padding-left: 15px;
  padding-bottom: 15px;
  z-index: 99;
  position: fixed;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
}
/* */

.nav-icon > * {
  margin: 2px;
  height: 4px;
  background-color: var(--text-color);
}

.first-stripe,
.second-stripe {
  width: 30px;
}

.third-stripe {
  width: 22px;
}

/* Nav Items */
.nav-items {
  z-index: 99;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  min-height: 60vh;
  width: 100vw;
  font-size: 2rem;
  padding: 20px;
  padding-top: 30px;
  border-radius: 10px 10px 20px 20px;
}

/* .nav-items.active {
  transform: translateX(0px);
  transition: all 500ms;
} */

.nav-items > * {
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

/* outer navbar for click outside */
.outer-nav-items {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 98;
}
